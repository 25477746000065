<template>
  <v-card flat>
    <v-data-table
      :items="table"
      :headers="[
        { text: 'Mannschaft', value: 'team.name', sortable: false },
        ...geraete(e, df).map(d => ({ text: d.name, value: d._discipline, sortable: false, align: 'center' })),
        { text: 'Penalty', value: 'result.penalty', sortable: false, align: 'center' },
        { text: 'Punkte', value: 'result.final', sortable: false, align: 'center' }
      ]"
      :dark="negative"
      hide-default-footer
      :mobile-breakpoint="0"
      :items-per-page="-1"
      class="table"
    >
      <template #item="{item}">
        <tr :class="{ even: item.i % 2 === 0, odd: item.i % 2 === 1 }">
          <td style="font-size: 80%; font-weight: bold;">{{ item.team.name }}</td>
          <td
            v-for="d in geraete(e, df)"
            :key="d._discipline"
            :style="{'font-size': '60%', 'font-weight': 500, 'text-align': 'center', 'color': item.geraete[d._discipline].result.finished ? 'inherit' : 'red'}"
          >
            {{ item.geraete[d._discipline].result.final | float2 }}
          </td>
          <td style="text-align: center;">
            {{ item.result.penalty | float1 }}
          </td>
          <td style="font-size: 80%; text-align: center; font-weight: bold;">
            {{ item.final | float2_0 }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p>Hinweis: bei rot dargestellte Wertungen haben noch nicht alle Turnerinnen der Mannschaft geturnt.</p>
  </v-card>
</template>

<script>
import { useCalc } from '@/views/components/stb_w_2023/plugins/calc'

export default {
  name: 'uebersicht',

  setup (props, context) {
    return {
      ...useCalc(props, context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    negative: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    table () {
      return this.e.teams.map(t => ({
        ...t,
        geraete: this.geraete(this.e, this.df).map(d => ({
          ...d,
          result: this.ergebnis(t.team._id, d._discipline)
        })).reduce((acc, curr) => {
          acc[curr._discipline] = curr
          return acc
        }, {}),
        result: t.results?.find(r => !r._discipline) || {}
      })).map(t => ({
        ...t,
        final: Object.values(t.geraete).reduce((acc, curr) => acc + curr.result.final, -(t.result.penalty || 0))
      })).sort((a, b) => a.final > b.final ? -1 : 1).map((t, i) => ({
        ...t,
        i
      }))
    }
  },

  methods: {
    ergebnis (tid, did) {
      const results = this.r?.filter(r => r._team === tid && r._discipline === did && r.subpart === null)

      return {
        final: this.mannschaftgeraetergebnis(this.e, this.r, tid, did),
        finished: results.every(r => r.final !== null)
      }
    }
  }
}
</script>

<style scoped>
.table {
  font-size: 150%;
}
</style>
